import { createStore } from 'vuex';
import currency from './currency/index';
import user from './user/index';
import order from './order/index';

export default createStore<any>({
  modules: {
    currency,
    user,
    order,
  },
});
