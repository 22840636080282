export interface IUserCart {
  currency: string,
  amount: number,
  name: string,
  orderNumber: number,
  merchantId: string
  privateKey: string,
  publicKey: string
}

export interface IUserState {
  userCart: IUserCart,
}

export enum USER {
  SET_USER_CART = 'SET_USER_CART'
}
