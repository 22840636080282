import { MutationTree } from 'vuex';
import {
  CURRENCY,
  ICurrencyState,
} from '@/types/store/currency';
import {
  ICoins,
  ICryptoCurrency,
} from '@/types/api';

const mutations: MutationTree<ICurrencyState> = {
  [CURRENCY.SET_CRYPTO_CURRENCY_LIST](store: ICurrencyState, payload: ICryptoCurrency[]) {
    store.cryptoCurrencyList = payload;
  },
  [CURRENCY.SET_NETWORKS_LIST](store: ICurrencyState, payload: ICryptoCurrency[]) {
    store.networksList = payload;
  },
  [CURRENCY.SET_COINS_MAP](store: ICurrencyState, payload: ICoins) {
    store.coinsRates = payload;
  },
};

export default mutations;
