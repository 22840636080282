import state from '@/store/user/state';
import getters from '@/store/user/getters';
import mutations from '@/store/user/mutations';
import { Module } from 'vuex';
import { IUserState } from '@/types/store/user';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
} as Module<IUserState, IUserState>;
