import { IUserState } from '@/types/store/user';

export const initState = (): IUserState => ({
  userCart: {
    currency: '',
    amount: 0,
    name: '',
    orderNumber: 0,
    merchantId: '',
    privateKey: '',
    publicKey: '',
  },
});

export default initState;
