import { ActionTree } from 'vuex';
import { CURRENCY, ICurrencyState } from '@/types/store/currency';
import api from '@/api';

const actions: ActionTree<ICurrencyState, ICurrencyState> = {
  async fetchCryptoCurrencyList({ commit }, networkId) {
    const res = await api.currencyService.getCryptoCurrencyList(networkId);
    const preparedRes = res.map((el) => {
      const memo = { ...el };
      memo.title = memo.name;
      return memo;
    });
    commit(CURRENCY.SET_CRYPTO_CURRENCY_LIST, preparedRes);
  },
  async fetchNetworksList({ commit }) {
    const res = await api.currencyService.getNetworksList();
    commit(CURRENCY.SET_NETWORKS_LIST, res);
  },
  async fetchCurrencyMap({ commit }) {
    const res = await api.currencyService.getCurrencyMap();
    commit(CURRENCY.SET_COINS_MAP, res);
  },
};

export default actions;
