import httpClient from '@/api/httpClient';
import {
  ICreateOrderPayload,
  ICreateOrderResponse,
  IMerchantItem,
} from '@/types/api';

export interface IOrderService {
  getMerchantList: ()=>Promise<IMerchantItem[]>
  createOrder: (params: ICreateOrderPayload)=>Promise<ICreateOrderResponse>
  getOrderAddress: (id: string)=>Promise<string>
  getOrderCancel: (id: string)=>Promise<boolean>
}

const getMerchantList = async (): Promise<IMerchantItem[]> => {
  const { data } = await httpClient.$get('merchants');
  if (data.ok) { return data.result.merchants; }
  return [];
};

const createOrder = async (params: ICreateOrderPayload): Promise<ICreateOrderResponse> => {
  const { data } = await httpClient.$post('order', { ...params });
  if (data.ok) { return data.result; }
  return {
    id: '',
    amount_token: null,
  };
};

const getOrderAddress = async (id: string): Promise<string> => {
  const { data } = await httpClient.$get(`orders/${id}/address`);
  if (data.ok) { return data.result.address; }
  return '';
};

const getOrderCancel = async (id: string): Promise<boolean> => {
  const { data } = await httpClient.$get(`orders/${id}/cancel`);
  if (data.ok) { return data.ok; }
  return false;
};

export default {
  getMerchantList,
  createOrder,
  getOrderAddress,
  getOrderCancel,
};
