import {
  ICoins,
  ICryptoCurrency, INetwork,
} from '@/types/api';
import httpClient from '../httpClient';

const {
  VUE_APP_CURRENCIES_URL,
  VUE_APP_APPLICATION_ID,
} = process.env;

export interface ICurrencyService {
  getCryptoCurrencyList: (networkId:string) => Promise<ICryptoCurrency[]>
  getNetworksList: () => Promise<INetwork[]>
  getCurrencyMap: () => Promise<ICoins>
}

const getCryptoCurrencyList = async (networkId:string): Promise<ICryptoCurrency[]> => {
  const { data } = await httpClient.$get(`networks/${networkId}/currencies`);
  if (data.ok) { return data.result; }
  return [];
};

const getNetworksList = async (): Promise<ICryptoCurrency[]> => {
  const { data } = await httpClient.$get('networks');
  if (data.ok) { return data.result; }
  return [];
};

const getCurrencyMap = async (): Promise<ICoins> => {
  const res = await httpClient.$get(`${VUE_APP_CURRENCIES_URL}latest.json?app_id=${VUE_APP_APPLICATION_ID}`);
  if (res.status === 200) {
    return res.data.rates;
  }
  return {};
};

export default {
  getCryptoCurrencyList,
  getCurrencyMap,
  getNetworksList,
};
