import {
  ICoins,
  ICryptoCurrency, INetwork,
} from '@/types/api';

export interface ICurrencyState {
  cryptoCurrencyList: ICryptoCurrency[],
  coinsRates: ICoins,
  networksList: INetwork[]
}

export enum CURRENCY {
  SET_CRYPTO_CURRENCY_LIST = 'SET_CRYPTO_CURRENCY_LIST',
  SET_COINS_MAP = 'SET_COINS_MAP',
  SET_NETWORKS_LIST = 'SET_NETWORKS_LIST'
}
