import state from '@/store/currency/state';
import getters from '@/store/currency/getters';
import actions from '@/store/currency/actions';
import mutations from '@/store/currency/mutations';
import { Module } from 'vuex';
import { ICurrencyState } from '@/types/store/currency';

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
} as Module<ICurrencyState, ICurrencyState>;
