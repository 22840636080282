import { setHTTPClientInstance } from './httpClient';
import currencyService, { ICurrencyService } from './rest/currency';
import authService, { IAuthService } from './rest/auth';
import orderService, { IOrderService } from './rest/order';

export interface IApiService {
  currencyService: ICurrencyService,
  authService: IAuthService,
  orderService: IOrderService,
}

export default {
  currencyService: { ...currencyService },
  authService: { ...authService },
  orderService: { ...orderService },
} as IApiService;

export {
  setHTTPClientInstance,
};
