import state from '@/store/order/state';
import getters from '@/store/order/getters';
import mutations from '@/store/order/mutations';
import actions from '@/store/order/action';
import { Module } from 'vuex';
import { IOrderState } from '@/types/store/order';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
} as Module<IOrderState, IOrderState>;
