import { IErrorTypes } from '@/types/constants';

export default {
  en: {
    order: 'Order Number',
    price: 'Price',
    chooseToken: 'Choose Token',
    address: 'Address',
    tokensAmount: 'Amount of Tokens',
    token: 'TOKEN',
    cancel: 'Cancel',
    generateAddress: 'Generate address',
    sentPayment: 'I’ve sent payment',
    instruction: 'To make a payment, generate an address and send the required number of tokens to this address',
    [IErrorTypes.CURRENCY_ERROR]: 'Wrong currency.',
    [IErrorTypes.BACKEND_ERROR]: 'Service is temporarily unavailable.',
  },
};
