import { MutationTree } from 'vuex';
import {
  IOrderState,
  ORDER,
} from '@/types/store/order';
import { IMerchantItem } from '@/types/api';

const mutations: MutationTree<IOrderState> = {
  [ORDER.SET_MERCHANT_LIST](store: IOrderState, payload: IMerchantItem[]) {
    store.merchantList = payload;
  },
};

export default mutations;
