import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
// @ts-ignore
import VueClipboard from 'vue3-clipboard';
// @ts-ignore
import vClickOutside from 'click-outside-vue3';
import store from '@/store';
import mixins from '@/mixins';
import api from './plugins/api';
import messages from './locales/messages';
import App from './App.vue';

const app = createApp(App);

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});
app.mixin(mixins);
app.use(i18n);
app.use(VueClipboard, {
  autoSetContainer: false,
  appendToBody: false,
});
app.use(vClickOutside);
app.use(api);
app.use(store);
app.mount('#app');
