import BigNumber from 'bignumber.js';

export default {
  methods: {
    $cn(value: string, max: number, min: number, pre = '', post = ''): string {
      if (typeof value === 'undefined') {
        return '-';
      }
      const bnValue = new BigNumber(value);
      const dp = bnValue.dp();
      // eslint-disable-next-line no-underscore-dangle
      const _min = dp < +max ? dp : +max;
      const minimumFractionDigits = typeof min === 'undefined' ? _min : min;
      const processed: string = bnValue.toFixed(max, 1).toString();
      const options = {
        maximumFractionDigits: max,
        minimumFractionDigits,
      };
      // @ts-ignore
      return `${pre}${this.$n(Number(processed), options)}${post}`;
    },
  },
};
