import axios from 'axios';
import api, { setHTTPClientInstance } from '@/api';
import store from '@/store';
import { ApiTokenProvider } from '@/utils/authHelper';

export default {
  install: (app: any) => {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    });

    instance.interceptors.request.use(
      (requestConfig) => {
        if (requestConfig && !requestConfig.url?.includes('openexchangerates.org')) {
          const config = requestConfig;
          const path = `${config.baseURL}${config.url}`.replace('https://cryptopay.ogpay.com', '');
          const { publicKey, privateKey } = store.getters['user/getUserCart'];
          const apiProvider = new ApiTokenProvider(privateKey, publicKey);
          const jwt = apiProvider.signJwt(path);
          // @ts-ignore
          config.headers.Authorization = `Bearer ${jwt}`;
          // @ts-ignore
          config.headers['X-API-KEY'] = publicKey;
          return config;
        }

        return requestConfig;
      },
    );

    setHTTPClientInstance(instance);
    app.config.globalProperties.$api = api;
  },
};
