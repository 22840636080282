import { sign } from 'jsonwebtoken';
import getUUID from '@/utils/randomStringGenerator';

export interface IAuthProvider {
  signJwt(path: string, bodyJson?: object): string;
  getPublicKey(): string;
}

export class ApiTokenProvider implements IAuthProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(private privateKey: string, private publicKey: string) {}

  signJwt(path: string, bodyJson?: object): string {
    const token = sign(
{
  uri: path,
  nonce: getUUID(20),
  iat: Math.floor(Date.now() / 1000),
  exp: Math.floor(Date.now() / 1000) + 30, // lifetime 30 seconds
  sub: this.publicKey,
} as object,
this.privateKey,
    );

    return token;
  }

  getPublicKey(): string {
    return this.publicKey;
  }
}
