import httpClient from '@/api/httpClient';

export interface IAuthService {
  getAuth: ()=>Promise<any>
}

const getAuth = async (): Promise<any> => {
  const params = {
    username: 'ogpaycrypto',
    password: 'dUL1x!pRsk0',
  };
  const { data } = await httpClient.$post('auth/login', { ...params });
  if (data.ok) {
    document.cookie = `AccessToken=${data.result.access}`;
  }
  return data.result;
};

export default {
  getAuth,
};
