import { MutationTree } from 'vuex';
import {
  IUserCart,
  IUserState,
  USER,
} from '@/types/store/user';

const mutations: MutationTree<IUserState> = {
  [USER.SET_USER_CART](store: IUserState, payload: IUserCart) {
    store.userCart = payload;
  },
};

export default mutations;
