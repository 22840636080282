import { ActionTree } from 'vuex';
import {
  IOrderState,
  ORDER,
} from '@/types/store/order';
import api from '@/api';

const actions: ActionTree<IOrderState, IOrderState> = {
  async fetchMerchantList({ commit }) {
    const res = await api.orderService.getMerchantList();
    commit(ORDER.SET_MERCHANT_LIST, res);
    return res;
  },
  async createOrder(_, payload) {
    return api.orderService.createOrder(payload);
  },
  async fetchOrderAddress(_, payload) {
    return api.orderService.getOrderAddress(payload);
  },
  async fetchOrderCancel(_, payload) {
    return api.orderService.getOrderCancel(payload);
  },
};

export default actions;
