import { GetterTree } from 'vuex';
import { ICurrencyState } from '@/types/store/currency';

const getters: GetterTree<ICurrencyState, ICurrencyState> = {
  getCryptoCurrencyList: (state: ICurrencyState) => state.cryptoCurrencyList,
  getNetworksList: (state: ICurrencyState) => state.networksList,
  getCoinsMap: (state: ICurrencyState) => state.coinsRates,
};

export default getters;
